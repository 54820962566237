define("my-care-git-ember-portal/services/file-upload", ["exports", "ember", "my-care-git-ember-portal/config/environment", "ember-concurrency", "ember-uploader"], function (exports, _ember, _myCareGitEmberPortalConfigEnvironment, _emberConcurrency, _emberUploader) {
    exports["default"] = _ember["default"].Service.extend({
        // env: Ember.inject.service('utils/env'),
        headers: {
            "Authorization": "Bearer " + localStorage.getItem(_myCareGitEmberPortalConfigEnvironment["default"].APP.AUTH_TOKEN_KEY)
        },
        fileTypeAllowList: (function () {
            if (this.get('isImage')) {
                return ['png', 'jpg', 'jpeg', 'gif'];
            } else {
                return ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'gif'];
            }
        }).property('isImage'),

        _fileUploadUrl: (function () {
            return _myCareGitEmberPortalConfigEnvironment["default"].APP.API_HOST + '/api/FileUpload';
        }).property(),

        uploadFile: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(file) {
            var self, uploadUrl, uploader;
            return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
                while (1) switch (context$1$0.prev = context$1$0.next) {
                    case 0:
                        self = this;

                        _ember["default"].$.ajaxPrefilter(function (options) {
                            options.headers = self.get('headers');
                        });
                        uploadUrl = this.get('_fileUploadUrl');
                        uploader = _emberUploader["default"].Uploader.create({
                            url: uploadUrl
                        });

                        uploader.on('progress', function (e) {
                            self.set("percent", e.percent);
                        });

                        return context$1$0.abrupt("return", uploader.upload(file).then(function (data) {
                            var thisFileName = String(file.name);
                            //set uploadedUrl
                            var uploadedUrl = String(data.media[0].url);
                            var fileType = String(data.media[0].type);
                            var uploadedFile = _ember["default"].Object.create({
                                type: fileType,
                                url: uploadedUrl,
                                fileName: thisFileName
                            });
                            return uploadedFile;
                        }));

                    case 6:
                    case "end":
                        return context$1$0.stop();
                }
            }, callee$0$0, this);
        })).drop(),

        fileCheck: function fileCheck(file, isImageOnly) {
            this.set('isImage', isImageOnly);
            if (!_ember["default"].isEmpty(file) && this.get('fileTypeAllowList').length > 0) {
                var fileExtention = file.name.split('.').pop().toLowerCase();
                var isFileTypeValid = false;
                for (var i = 0; i < this.get('fileTypeAllowList').length; i++) {
                    if (this.get('fileTypeAllowList')[i].indexOf(fileExtention) === 0) {
                        isFileTypeValid = true;
                    }
                }

                if (!isFileTypeValid) {
                    var fileTypeAllowListString = "";
                    for (var j = 0; j < this.get('fileTypeAllowList').length; j++) {
                        this.get('fileTypeAllowList').objectAt(j);
                        fileTypeAllowListString += ' ' + this.get('fileTypeAllowList').objectAt(j);
                    }
                    return "Only the following file types can be uploaded:" + fileTypeAllowListString;
                } else {
                    return true;
                }
            } else {
                return "Your file is empty.";
            }
        },

        isFileTooLarge: function isFileTooLarge(fileSize, limitSize) {
            if (!limitSize) {
                limitSize = 4000000;
            }

            if (fileSize < limitSize) {
                return true;
            } else {
                return "The file size can not exceed " + limitSize / 1000 / 1000 + "MB.";
            }
        },

        formatData: function formatData(source) {
            var data = source.replace(/^data:image\/png;base64,/, '');
            data = data.replace(/^data:image\/jpeg;base64,/, '');
            return data;
        }

    });
});