define('my-care-git-ember-portal/components/file-upload', ['exports', 'ember', 'ember-uploader', 'my-care-git-ember-portal/config/environment'], function (exports, _ember, _emberUploader, _myCareGitEmberPortalConfigEnvironment) {

		/**
   * File Upload component <br>
   * This component requries Authorization header to be included explicitly since it is not
   * hooked into ember-data adapter.
   * Options <br>
   *   url: the url to upload files to
   *   uploadedUrl: the url for uploaded file after uploading is successful
   */
		exports['default'] = _emberUploader['default'].FileField.extend({
				url: '', //serverUrl used to upload
				uploadedUrl: '', //server returned url where the uploaded image can be accessed
				files: [],
				isImage: false,
				precentage: 0,
				//fileTypeAllowList:[],
				//Ajax header for authorization
				headers: {
						"Authorization": "Bearer " + localStorage.getItem(_myCareGitEmberPortalConfigEnvironment['default'].APP.AUTH_TOKEN_KEY)
				},

				fileTypeAllowList: (function () {
						if (this.get('isImage')) {
								return ['png', 'jpg', 'jpeg', 'gif'];
						} else {
								return ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'gif'];
						}
				}).property('isImage'),

				click: function click() {
						this.set('files', []);
						this.sendAction('emptyerrors');
				},

				//Callback for when file is selected.
				filesDidChange: (function () {
						var self = this;
						var files = self.get('files');
						//Add custom headers to Ajax Filter. In our case, this should be Authorization header.
						_ember['default'].$.ajaxPrefilter(function (options) {
								options.headers = self.get('headers');
						});

						//Make sure file is uploaded with the 'key' name
						self.$('input').attr('name', 'file');

						//Create uploader with given upload url
						var uploadUrl = this.get('url');
						var uploader = _emberUploader['default'].Uploader.create({
								url: uploadUrl
						});

						uploader.on('progress', function (e) {
								self.set("precentage", e.percent);
						});

						//Whenever user selected a file
						var file = files[0];
						if (file) {
								var checkResult = self.fileCheck(file);
								var checkFilesize = self.isFileToolarge(file.size);
								if (checkResult === true && checkFilesize === true) {
										uploader.upload(file).then(function (data) {
												self.set('fileName', file.name);
												//set uploadedUrl
												self.set('uploadedUrl', data.media[0].url);

												if (self.action) {
														data.media[0].fileName = file.name;
														self.sendAction('action', data.media[0]);
												}
										}, function (error) {
												console.log(error);
												toastr.info('Error uploading your file');
										})['finally'](function () {
												self.set('precentage', 0);
										});
								} else {
										if (checkResult !== true) {
												toastr.info(checkResult);
										}
										if (checkFilesize !== true) {
												toastr.info(checkFilesize);
										}
								}
						}
				}).observes("files"),

				fileCheck: function fileCheck(file) {
						if (!_ember['default'].isEmpty(file) && this.get('fileTypeAllowList').length > 0) {
								var fileExtention = file.name.split('.').pop().toLowerCase();
								var isFileTypeValid = false;
								for (var i = 0; i < this.get('fileTypeAllowList').length; i++) {
										if (this.get('fileTypeAllowList')[i].indexOf(fileExtention) === 0) {
												isFileTypeValid = true;
										}
								}

								if (!isFileTypeValid) {
										var fileTypeAllowListString = "";
										for (var j = 0; j < this.get('fileTypeAllowList').length; j++) {
												this.get('fileTypeAllowList').objectAt(j);
												fileTypeAllowListString += ' ' + this.get('fileTypeAllowList').objectAt(j);
										}
										return "Only the following file types can be uploaded:" + fileTypeAllowListString;
								} else {
										return true;
								}
						} else {
								return "Your file is empty.";
						}
				},

				isFileToolarge: function isFileToolarge(fileSize, limitSize) {
						if (!limitSize) {
								limitSize = 4000000;
						}

						if (fileSize < limitSize) {
								return true;
						} else {
								return "The file size can not exceed " + limitSize / 1000 / 1000 + "MB.";
						}
				}
		});
});